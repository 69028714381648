<template>
    <div>
        <keep-alive>
            <router-view/>
        </keep-alive>
        <van-tabbar
                v-model="active"
                route active-color="#4D77FD"
                inactive-color="#606266"
                placeholder
                z-index="98"
                safe-area-inset-bottom
                class="index"
        >
            <van-tabbar-item to="/bookcase">
                <span>书架</span>
                <template #icon="props">
                    <img v-if="props.active == 0" src="../assets/images/tabBar/tab_icon1_def.png"/>
                    <img v-else src="../assets/images/tabBar/tab_icon1_sel.png"/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/library">
                <span>书库</span>
                <template #icon="props">
                    <img v-if="props.active == 0" src="../assets/images/tabBar/tab_icon2_def.png"/>
                    <img v-else src="../assets/images/tabBar/tab_icon2_sel.png"/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/welfare">
                <span>福利</span>
                <template #icon="props">
                    <img v-if="props.active == 0" src="../assets/images/tabBar/tab_icon3_def.png"/>
                    <img v-else src="../assets/images/tabBar/tab_icon3_sel.png"/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/my">
                <span>我的</span>
                <template #icon="props">
                    <img v-if="props.active == 0" src="../assets/images/tabBar/tab_icon4_def.png"/>
                    <img v-else src="../assets/images/tabBar/tab_icon4_sel.png"/>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        name: "Index",
        data() {
            return {
                active: 0,

            }
        },
        methods: {

        }
    }
</script>

<style scoped>
    .index {
        /*margin-top: 40px;*/
    }
</style>